<template>
  <div>
    <a
      href="#"
      class="btn btn-light-facebook btn-sm px-4 custom-btn text-no-wrap"
      @click="toggleModal"
      >View</a
    >
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="850"
      v-if="items"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">{{ items.title }}</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
        >
          <!--begin::Body-->
          <div class="mt-5">
            <v-data-table
              fixed-header
              light
              dense
              :items="items.data"
              :headers="headers"
              item-key="validation_id"
              class="m-2 font-weight-bold"
              hide-default-footer
            >
              <template v-slot:no-data> NO DATA HERE! </template>
            </v-data-table>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "PlatformActions",
  props: {
    itemForAction: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    dialog: false,
    tabIndex: 0,
    selected: null,
    items: null,
    headers: [
      {
        text: "Action ID",
        value: "action_id",
      },
      {
        text: "Platform Action",
        value: "platform_action",
      },
      {
        text: "System Action",
        value: "system_action",
      },
    ],
  }),
  methods: {
    toggleModal() {
      this.items = this.itemForAction;
      this.dialog = !this.dialog;
    },
  },
};
</script>
