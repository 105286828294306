<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    persistent
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :id="value.system_id" text v-bind="attrs" v-on="on">
        <text-column
          :item="item"
          :value="value.system_id"
          :header="value.system_id"
        ></text-column>
      </v-btn>
    </template>
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Cod detail</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-card>
          <div
            v-for="(obj, index) in Object.entries(value.detail)"
            :key="index"
          >
            <div id="carrier_statuses" v-if="obj[0] == 'carrier_statuses'">
              <v-card class="mt-3">
                <v-toolbar color="primary h5" dark>Carrier status</v-toolbar>
                <v-card-text class="pa-4 text-center">
                  <v-timeline v-if="obj[1].value">
                    <v-timeline-item
                      v-for="(status, i) in obj[1].value"
                      :key="i"
                      color="primary"
                      large
                    >
                      <template v-slot:opposite>
                        <span>
                          <!-- {{
                        new Date(status.time * 1000).toLocaleDateString()
                      }} -->
                          {{ status.date }}
                        </span>
                      </template>
                      <v-card class="elevation-2">
                        <v-card-title class="headline">
                          {{ status.label }}
                        </v-card-title>
                        <v-card-text v-if="status.note">
                          {{ status.note }}
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                  <div v-else>NO DATA</div>
                </v-card-text>
              </v-card>
            </div>
            <div
              id="customer_statuses"
              v-else-if="obj[0] == 'customer_statuses'"
            >
              <v-card class="mt-3">
                <v-toolbar color="primary h5" dark>Customer status</v-toolbar>
                <v-card-text class="pa-4 text-center">
                  <v-timeline v-if="obj[1].value">
                    <v-timeline-item
                      v-for="(status, i) in obj[1].value"
                      :key="i"
                      color="primary"
                      large
                    >
                      <template v-slot:opposite>
                        <span>
                          <!-- {{
                        new Date(status.time * 1000).toLocaleDateString()
                      }} -->
                          {{ status.date }}
                        </span>
                      </template>
                      <v-card class="elevation-2">
                        <v-card-title class="headline">
                          {{ status.label }}
                        </v-card-title>
                        <v-card-text v-if="status.note">
                          {{ status.note }}
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                  <div v-else>NO DATA</div>
                </v-card-text>
              </v-card>
            </div>
            <v-card-text v-else>
              <span>{{ obj[1].title }}</span> : <span>{{ obj[1].value }}</span>
            </v-card-text>
          </div>
        </v-card>
      </v-card-text>
      <div style="flex: 1 1 auto"></div>
    </div>
  </v-dialog>
</template>

<script>
/**
 * component for showing hidden data on cod page
 * activates on double click event on row
 * api name = codHiddenData
 */
import TextColumn from "@/own/components/datatable/TextColumn.vue";
export default {
  name: "CodHiddenData",
  props: ["value", "item"],
  components: {
    TextColumn,
  },
  data: () => ({
    dialog: false,
  }),

  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style scoped>
div {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
}
.other_key {
  margin: 2px;
}
.other_value {
  font-weight: bold;
  margin: 2px;
  margin-left: 6px;
}
</style>
