import Swal from "sweetalert2";
import {
  editItemMessage,
  successTitle,
} from "@/core/constants/globalVariables";

const swalEdited = () => {
  Swal.fire({
    title: `${successTitle}`,
    text: `${editItemMessage}`,
    icon: "success",
    showConfirmButton: false,
    timer: 2500,
  });
};

export default swalEdited;
