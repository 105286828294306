<template>
  <v-btn
    text
    color="primary"
    :disabled="!isFileExists()"
    @click="downloadFile()"
    class="w-100 my-1"
  >
    <span class="second-text"><v-icon>mdi-download</v-icon> </span>
  </v-btn>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "DownloadFile",
  props: ["value"],
  methods: {
    downloadFile() {
      this.$store.commit(SET_PAGE_LOADING, true);
      try {
        const link = document.createElement("a");
        link.href = this.value.path;
        link.download = "file.txt";
        link.click();
        this.$store.commit(SET_PAGE_LOADING, false);
      } catch {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
    isFileExists() {
      return !!this.value.exists;
    },
  },
};
</script>
