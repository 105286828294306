<template>
  <Multiselect
    v-model="filterData"
    class="multi-select-filter-sidebar mx-1"
    tag-placeholder="Add this as new tag"
    :placeholder="filter.title"
    :options="options"
    label="name"
    track-by="code"
    openDirection="bottom"
    :multiple="true"
    :taggable="true"
    ref="selectRef"
    @tag="(query) => addTag(query)"
  >
    <template #clear>
      <div
        class="multiselect__clear"
        v-if="filterData.length"
        @click.prevent.stop="resetFilterData"
      ></div>
    </template>
  </Multiselect>
</template>

<script>
import { SET_FILTERS_DATA } from "@/core/services/store/filter.module";
import Multiselect from "vue-multiselect";
export default {
  name: "FilterTextInputMultipleNew",
  components: { Multiselect },
  props: {
    /**
      * @type {
          {
              name: String,
              title: String,
              related_columns: Array,
              type: String,
              values: Array,
          }
                }
    */
    filter: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    filterData: [],
    options: [],
  }),
  mounted() {
    if (this.filterAllData[`${this.filter.name}`]) {
      this.filterData = this.filterAllData[`${this.filter.name}`];
    }
  },
  computed: {
    filterAllData() {
      return this.$store.getters.getFiltersData;
    },
  },
  methods: {
    addTag(newTag) {
      newTag
        .trim()
        .replace(/\s\s+/g, " ")
        .split(" ")
        .forEach((element) => {
          this.options.push({
            name: element,
            code:
              element.substring(0, 2) + Math.floor(Math.random() * 10000000),
          });
          this.filterData.push({
            name: element,
            code:
              element.substring(0, 2) + Math.floor(Math.random() * 10000000),
          });
        });

      setTimeout(() => {
        this.$refs.selectRef.$el.focus();
      }, 100);
    },
    resetFilterData() {
      this.options = [];
      this.filterData = [];
    },
  },
  watch: {
    filterData: {
      handler() {
        const all = this.filterAllData;
        all[`${this.filter.name}`] = this.filterData.map((item) => item.name);
        this.$store.commit(SET_FILTERS_DATA, all);
      },
      deep: true,
    },
  },
};
</script>
