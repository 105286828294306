<template>
  <span :class="classSelector(header)" :id="id"> {{ value }}</span>
</template>

<script>
/**
 * display raw styled text on columns
 * api name = text
 */
export default {
  name: "TextColumn",
  props: ["value", "id", "header"],
  methods: {
    classSelector(header) {
      let result = "second-text";
      if (
        header.value === "name" ||
        header.value === "id" ||
        header.export_order === 2
      )
        result = "first-text";
      if (header.value === "description") {
        header.width = "400px";
        result += " text-wrap";
      }
      return result;
    },
  },
};
</script>
