<template>
  <v-card class="mt-4">
    <v-card-title class="primary h5 text-white">{{ item.title }}</v-card-title>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab v-for="(i, index) in tabs" :key="index">
          {{ i }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(j, index) in contents" :key="index">
          <v-card color="basil" flat>
            <v-card-text
              class="json-view-container min-h-500px"
              style="
                background-color: #120f12 !important;
                border-radius: 0;
                color: white;
              "
            >
              <vue-json-pretty :data="j" :showLine="false" />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  name: "TabJson",
  components: {
    VueJsonPretty,
  },
  props: ["item"],
  data: () => ({
    tab: 0,
  }),
  computed: {
    tabs: function () {
      return this.item.data.map((i) => i.title);
    },
    contents: function () {
      return this.item.data.map((i) => i.value);
    },
  },
};
</script>

<style></style>
