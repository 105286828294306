<template>
  <v-card id="consignee" class="mt-4">
    <v-card-title class="primary h5 text-white">{{ item.title }}</v-card-title>
    <v-card-text style="font-size: 16px">
      <v-row class="my-4">
        <v-simple-table>
          <template v-slot:default>
            <tbody class="border-0 w-100">
              <tr v-for="(element, index) in item.data" :key="index">
                <td class="px-3 py-0 h-30px">
                  <span class="other_key font-size-lg"
                    >{{ element.title }}:
                  </span>
                </td>
                <td class="px-3 py-0 h-30px">
                  <span
                    class="other_value font-weight-bold font-size-base"
                    :style="element.style"
                    >{{ element.value }}</span
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PopUpTextGroup",
  props: ["item"],
};
</script>

<style>
vjs-tree-node.is-highlight,
.vjs-tree-node:hover {
  background-color: transparent !important;
}
</style>
