<template>
  <v-card class="mt-4">
    <v-toolbar color="primary h5 text-white">{{ item.title }}</v-toolbar>
    <v-card-text class="pa-4">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <th class="" v-for="(head, i) in item.data.header" :key="i">
              {{ head }}
            </th>
          </thead>
          <tbody v-if="item.data.body.length > 0">
            <tr v-for="(data, index) in item.data.body" :key="index">
              <td v-for="(el, j) in data" :key="j">{{ el }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <div>No Data Available!</div>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PopUpTable",
  props: ["item"],
};
</script>

<style></style>
