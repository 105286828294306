<template>
  <div>
    <div class="second-text" v-for="subItem in value.data" :key="subItem">
      <span class="badge font-size-sm my-1 py-2 px-4 status-picked w-100">
        {{ subItem }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonLike",
  props: ["value"],
};
</script>

<style scoped>
.status-active,
.status-confirmed,
.status-picked,
.status-delivered,
.status-partially-picked {
  color: #14b519;
  background-color: #e1f7e3 !important;
}
</style>
