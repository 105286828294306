<template>
  <v-menu left transition="scale-transition" offset-y bottom rounded>
    <template v-slot:activator="{ on, attrs }">
      <a
        v-bind="attrs"
        v-on="on"
        class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
      >
        Actions
        <span class="svg-icon m-0">
          <v-icon small>mdi-chevron-down</v-icon>
        </span>
      </a>
    </template>
    <v-card class="poppins py-1" elevation="1">
      <template
        v-for="(action, index) in actions.filter(
          (action) => action.isVisible === true
        )"
      >
        <div :key="index">
          <v-btn
            class="w-100 text-left"
            :disabled="!actionStatus(action)"
            :class="!actionStatus(action) ? 'text-muted' : 'text-dark'"
            @click="
              () => {
                if (item.status_label === 'Unsubscribed') {
                  return;
                } else {
                  handle_function_call(action.method, item);
                }
              }
            "
            text
          >
            <template v-if="action.type === 'status'">
              <span v-if="item.is_active">
                {{
                  item.is_active === "Yes" || item.is_active === "YES"
                    ? "Suspend"
                    : "Unsuspend"
                }}
              </span>
              <span
                v-else-if="item.status_label"
                :class="{
                  'text-muted':
                    item.status_label === 'Cancelled' ||
                    item.status_label === 'Unsubscribed',
                }"
              >
                {{ statusLabel }}
              </span>
              <span v-else-if="item.state_label">
                {{ item.state_label !== "Active" ? "Unsuspend" : "Suspend" }}
              </span>
              <span v-else>
                {{ item.status !== "Suspended" ? "Suspend" : "Unsuspend" }}
              </span>
            </template>

            <template v-else>
              <span>{{ action.title }}</span>
            </template>
          </v-btn>
        </div>
      </template>
    </v-card>
  </v-menu>
</template>

<script>
/**
 * universal action button creator component for datatables
 * @var item
 */

export default {
  name: "DataTableActionSelector",
  props: {
    item: { required: true },
    actions: { required: true },
    handle_function_call: { required: true },
  },
  methods: {
    actionStatus(action) {
      let status = true;
      try {
        if (this.item.extra_data.detail) {
          if (this.item.extra_data.detail.actions[action.name] === true) {
            status = true;
          } else if (
            this.item.extra_data.detail.actions[action.name] === false
          ) {
            status = false;
          }
        }
      } catch {
        status = true;
      }

      return status;
    },
  },
  computed: {
    statusLabel: function () {
      let label = null;
      if (this.item.status_label === "Enabled") label = "Disable";
      else if (
        this.item.status_label === "Disabled" ||
        this.item.status_label === "Draft"
      )
        label = "Enable";
      else if (this.item.status_label === "Going on") label = "Cancel";
      else if (this.item.status_label === "Cancelled") label = "Cancel";
      else if (this.item.status_label === "Active") label = "Inactive";
      else if (this.item.status_label === "Pending") label = "Verify";
      else if (this.item.status_label === "Verified") label = "Disable";
      else if (this.item.status_label === "Disable") label = "Enable";
      else if (this.item.status_label === "Unsubscribed") label = "Enable";
      return label;
    },
  },
};
</script>
