<template>
  <div id="other_status">
    <v-card class="mt-4">
      <v-toolbar color="primary h5" dark>{{ item.title }}</v-toolbar>
      <v-card-text class="pa-4">
        <v-timeline>
          <v-timeline-item
            v-for="(status, i) in item.data"
            :key="i"
            color="primary"
            large
          >
            <template v-slot:opposite>
              <span> {{ dateFormatter(new Date(status.time * 1000)) }}</span>
            </template>
            <v-card class="elevation-2">
              <v-card-title class="headline">
                {{ status.label }}
              </v-card-title>
              <v-card-text v-if="status.note">
                {{ status.note }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "PopUpTimeLine",
  props: ["item"],
  methods: {
    dateFormatter(date) {
      let d = date;
      return (
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          // d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":") +
        " " +
        [
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getFullYear(),
        ].join("-")
      );
    },
  },
};
</script>

<style></style>
