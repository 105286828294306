<template>
  <div v-if="!!serverData">
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="1000"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Edit Order Method</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="createForm" class="py-3 row">
            <div class="h-75px my-0 col-12 col-sm-6 col-md-4">
              <v-autocomplete
                dense
                outlined
                v-model="formData.integration_id"
                :items="serverData.integrations"
                item-name="text"
                item-value="index"
                label="Integration"
                clearable
                :error-messages="integration_idErrors"
                @input="$v.formData.integration_id.$touch()"
                @blur="$v.formData.integration_id.$touch()"
              ></v-autocomplete>
            </div>
            <div class="h-75px my-0 col-12 col-sm-6 col-md-4">
              <v-autocomplete
                dense
                outlined
                v-model="formData.apply_type_id"
                :items="serverData.apply_types"
                item-name="text"
                item-value="index"
                label="Apply type"
              ></v-autocomplete>
            </div>
            <div
              v-if="formData.apply_type_id === 2"
              class="h-75px my-0 col-12 col-sm-6 col-md-4"
            >
              <v-autocomplete
                dense
                outlined
                v-model="formData.carrier_id"
                :items="serverData.carriers"
                item-name="text"
                item-value="index"
                label="Carrier"
                clearable
                :error-messages="carrier_id_errors"
                @input="$v.formData.carrier_id.$touch()"
                @blur="$v.formData.carrier_id.$touch()"
              ></v-autocomplete>
            </div>

            <div
              v-if="formData.apply_type_id === 1"
              class="h-75px my-0 col-12 col-sm-6 col-md-4"
            >
              <v-select
                dense
                outlined
                v-model="formData.shipping_type_id"
                :items="serverData.shipping_types"
                item-name="text"
                item-value="index"
                label="Internal Shipping Types"
                clearable
                :error-messages="shipping_type_idErrors"
                @input="$v.formData.shipping_type_id.$touch()"
                @blur="$v.formData.shipping_type_id.$touch()"
              ></v-select>
            </div>
            <div class="h-75px my-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                dense
                outlined
                v-model="formData.store_code"
                label="External Code"
                required
                clearable
                :error-messages="store_codeErrors"
                @input="$v.formData.store_code.$touch()"
                @blur="$v.formData.store_code.$touch()"
              ></v-text-field>
            </div>
            <!--end::Body-->
          </form>

          <!--begin::Actions-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center mt-10">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { editItemMessage } from "@/core/constants/globalVariables";
import ApiService from "@/core/services/api.service";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      integration_id: { required },
      shipping_type_id: {
        required: requiredIf(function () {
          return this.formData.apply_type_id === 1;
        }),
      },
      store_code: { required },
      carrier_id: {
        required: requiredIf(function () {
          return this.formData.apply_type_id === 2;
        }),
      },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      apply_type_id: null,
      id: null,
      integration_id: null,
      shipping_type_id: null,
      store_code: null,
      carrier_id: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].url && errors.push("This url is invalid");
      }
      if ("numeric" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = {
        id: { ...this.$store.getters.getItemForAction }.id,
        integration_id: this.$store.getters.getINTEGRATIONS2IntegrationId,
      };
      ApiService.post(
        "/validations/datahub/platform_shipping_methods/edit",
        data
      )
        .then((response) => {
          this.serverData = response.data;
          this.formData = this.serverData.platform_shipping_method;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },

    submitEditForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let data = this.formData;
        ApiService.post(
          "/validations/datahub/platform_shipping_methods/update",
          data
        )
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `${editItemMessage}`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetEditForm() {
      this.$v.$reset();
      try {
        this.formData = this.serverData.status;
      } catch {
        this.formData = {
          apply_type_id: 1,
          id: null,
          integration_id: null,
          shipping_type_id: null,
          store_code: null,
          carrier_id: null,
        };
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
        this.serverData = null;
      } else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
  },
  computed: {
    integration_idErrors() {
      return this.handleFormValidation("integration_id");
    },
    shipping_type_idErrors() {
      return this.handleFormValidation("shipping_type_id");
    },
    store_codeErrors() {
      return this.handleFormValidation("store_code");
    },
    carrier_id_errors() {
      return this.handleFormValidation("carrier_id");
    },
    carrierAccounts() {
      return this.serverData.carrier_accounts.filter(
        (account) => account.carrier_id === this.formData.carrier_id
      );
    },
  },
};
</script>
