<template>
  <div>
    <v-dialog transition="dialog-bottom-transition" max-width="800">
      <template v-slot:activator="{ on, attrs }">
        <a
          href="#"
          class="btn btn-light-linkedin btn-sm px-4 ls1 text-no-wrap"
          v-bind="attrs"
          v-on="on"
          >{{ item.sales_person.title }}</a
        >
      </template>
      <template v-slot:default="dialog">
        <!--begin::Modal dialog-->
        <div class="bg-white poppins">
          <!--begin::Modal header-->
          <div class="modal-header py-4 align-center">
            <h4 class="mb-0 font-weight-bolder">Sale Person</h4>
            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-light-info"
              @click="dialog.value = false"
            >
              <span class="svg-icon">
                <v-icon size="22">mdi-close</v-icon>
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--begin::Modal header-->
          <!--begin::Modal body-->
          <div
            class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7"
          >
            <!--begin::Body-->
            <div
              class="d-flex align-items-center mb-5 h-65px"
              v-for="person in item.sales_person.data"
              :key="person.name"
            >
              <div class="symbol symbol-50 mr-5">
                <img
                  v-if="person.photo.url"
                  :alt="person.name"
                  :src="person.photo.url"
                />
                <span v-else class="symbol symbol-35 symbol-light-primary">
                  <span class="symbol-label font-size-h5 font-weight-medium">
                    {{ person.photo.first_letter }}
                  </span>
                </span>
              </div>
              <h5
                class="mb-0 font-weight-bolder font-size-h5 text-dark-75 text-hover-info"
              >
                {{ person.name }}
              </h5>
            </div>

            <!--end::Body-->
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal dialog-->
      </template>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ShowSalePerson",
  props: ["item"],
};
</script>
