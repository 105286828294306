export default {
  methods: {
    /**
     *
     * @param {string} field name of the field
     * @param {number} minValue default value 0
     * @param {string} fieldParent set to null if not nested
     */
    validateMinValue(field, minValue = 0, fieldParent = "formData") {
      if (fieldParent) {
        if (parseInt(this[`${fieldParent}`][`${field}`]) < minValue) {
          this[`${fieldParent}`][`${field}`] = minValue;
        }
      } else {
        if (parseInt(this[`${field}`]) < minValue) {
          this[`${field}`] = minValue;
        }
      }
    },
    /**
     *
     * @param {string} field name of the field
     * @param {number} maxValue default value 0
     * @param {string} fieldParent set to null if not nested
     */
    validateMaxValue(field, maxValue = 999999, fieldParent = "formData") {
      if (fieldParent) {
        if (parseInt(this[`${fieldParent}`][`${field}`]) < maxValue) {
          this[`${fieldParent}`][`${field}`] = maxValue;
        }
      } else {
        if (parseInt(this[`${field}`]) >= maxValue) {
          this[`${field}`] = maxValue;
        }
      }
    },
  },
};
