<template>
  <span class="second-text" :id="store.id">
    <div class="d-flex align-content-left w-auto pr-2 py-2">
      <span class="symbol symbol-35 symbol-light-success text-left px-1">
        <img v-if="store.logo" :alt="store.store" :src="store.logo" />
        <span v-else class="symbol-label font-size-h5 font-weight-bold">
          {{ firstLetter }}
        </span>
      </span>

      <span class="text-dark-50 font-size-base w-auto p-1">
        {{ store.store }}
      </span>
    </div>
  </span>
</template>

<script>
export default {
  name: "StoreItem",
  props: ["store"],
  computed: {
    firstLetter: function () {
      return this.store.store.slice(0, 1);
    },
  },
};
</script>
