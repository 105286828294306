<template>
  <v-dialog transition="dialog-bottom-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <button
        class="btn btn-light-twitter btn-sm px-4 ls1 text-no-wrap"
        v-bind="attrs"
        v-on="on"
      >
        {{ title }}
      </button>
      <!--        <v-btn color="primary" text v-bind="attrs" v-on="on">{{ title }}</v-btn>-->
    </template>
    <template v-slot:default="dialog">
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Role entities</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog.value = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <v-treeview dense :items="entities"></v-treeview>
          <!--end::Body-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </template>
  </v-dialog>
</template>

<script>
/**
 * component for displaying entity permission column relation on roles page
 * api name = showEntities
 */
export default {
  name: "ShowEntities",
  props: ["entities", "title"],
  data: () => ({
    dialog: false,
  }),
};
</script>
