<template>
  <div>
    <v-dialog transition="dialog-bottom-transition" max-width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark text v-bind="attrs" v-on="on"
          ><h6>{{ item.title }}</h6>
        </v-btn>
      </template>
      <template v-slot:default="dialog">
        <div class="bg-white poppins">
          <h3 class="mb-0 font-weight-bolder">Sale Person</h3>
          <v-card-text class="pa-4">
            <div
              class="d-flex align-items-center mt-5"
              v-for="person in item.data"
              :key="person.name.name"
            >
              <div class="symbol symbol-100 mr-5">
                <img
                  v-if="person.name.photo"
                  :alt="person.name.name"
                  :src="person.name.photo"
                />
                <span v-else class="symbol symbol-35 symbol-light-success">
                  <span class="symbol-label font-size-h5 font-weight-bold">
                    {{ person.name.name.charAt(0).toUpperCase() }}
                  </span>
                </span>
                <i class="symbol-badge bg-success"></i>
              </div>
              <div class="d-flex flex-column">
                <div
                  class="font-weight-bold font-size-h5 text-dark-75 text-hover-info"
                >
                  {{ person.name.name }}
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog.value = false">Close</v-btn>
          </v-card-actions>
        </div>
      </template>
    </v-dialog>
  </div>
</template>

<script>
/**
 * display sales person
 * api name = showSalePerson
 */
export default {
  name: "ShowSalePerson",
  props: ["item"],
};
</script>
