<template>
  <div>
    <div class="first-text" v-for="(value, index) in items" :key="index">
      {{ value }}
    </div>
  </div>
</template>

<script>
/**
 * component for displaying name with email in datatable columns
 * api name = name_email
 */
export default {
  name: "ListText",
  props: ["items"],
};
</script>
