<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    transition="dialog-bottom-transition"
    class="h-100"
  >
    <template v-slot:activator="{ on, attrs }" v-if="value.header">
      <button
        class="btn btn-light-facebook btn-sm px-4 custom-btn text-no-wrap"
        @click="toggleModal"
      >
        View
      </button>
    </template>
    <div
      class="bg-white poppins h-100 d-flex flex-column justify-justify-content-between justify-center"
    >
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder"></h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <v-card-text class="px-8 pt-6 pb-0 h-100">
        <div class="table-responsive overflow-y-auto" style="max-height: 70vh">
          <table
            v-if="value.header"
            class="table table-head-custom table-vertical-center table-head-bg table-row-bordered poppins"
          >
            <thead>
              <tr>
                <th
                  class="pl-3"
                  v-for="(item, index) in value.header"
                  :key="index"
                >
                  <span class="text-dark-75">{{ item }}</span>
                </th>
              </tr>
            </thead>
            <tbody v-if="value.body.length > 0">
              <tr v-for="(item, index) in value.body" :key="index">
                <td
                  class="px-3 text-dark-65 font-weight-bolder font-size-lg"
                  v-for="(col, colIndex) in item"
                  :key="colIndex"
                >
                  <span v-if="colIndex < item.length - 1">{{ col }}</span>
                  <span v-else>
                    <button
                      type="reset"
                      class="btn btn-light mr-3 px-5 py-3 ls1"
                      @click="showData(col)"
                    >
                      Show
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
            <caption
              v-else
              class="mt-2 text-dark-50 text-center font-weight-bolder font-size-lg"
            >
              No Data
            </caption>
          </table>
        </div>
      </v-card-text>

      <v-card-actions class="">
        <v-spacer></v-spacer>
        <button
          type="reset"
          class="btn btn-light-info mr-3 px-5 py-3 ls1"
          @click="dialog = false"
        >
          Close
        </button>
      </v-card-actions>
    </div>
    <v-dialog
      v-model="dialog2"
      transition="dialog-bottom-transition"
      persistent
    >
      <div
        class="bg-white poppins h-100 d-flex flex-column justify-justify-content-between justify-center"
      >
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder"></h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal2"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <v-card-text class="px-8 pt-6 pb-0 h-100">
          <div
            class="table-responsive overflow-y-auto"
            style="max-height: 75vh"
          >
            <div>{{ detail }}</div>
          </div>
        </v-card-text>

        <v-card-actions class="">
          <v-spacer></v-spacer>
          <button
            type="reset"
            class="btn btn-light-info mr-3 px-5 py-3 ls1"
            @click="toggleModal2"
          >
            Close
          </button>
        </v-card-actions>
      </div>
    </v-dialog>
  </v-dialog>
</template>

<script>
export default {
  name: "ShowSkusExTable",
  props: ["value"],
  data: () => ({
    dialog: false,
    dialog2: false,
    detail: null,
  }),
  methods: {
    showData(data) {
      this.detail = data;
      this.toggleModal2();
    },
    toggleModal() {
      this.dialog = !this.dialog;
    },
    toggleModal2() {
      if (this.dialog2) this.detail = null;
      this.dialog2 = !this.dialog2;
    },
  },
};
</script>
