<template>
  <div class="second-text text-center">
    <div>{{ value.name }}</div>
    <div>{{ value.email }}</div>
  </div>
</template>

<script>
/**
 * component for displaying name with email in datatable columns
 * api name = name_email
 */
export default {
  name: "NameEmail",
  props: ["value"],
};
</script>
