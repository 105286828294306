<template>
  <v-menu left transition="scale-transition" offset-y bottom rounded>
    <template v-slot:activator="{ on, attrs }">
      <a
        v-bind="attrs"
        v-on="on"
        class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
      >
        Actions
        <span class="svg-icon svg-icon-5 m-0">
          <v-icon small>mdi-chevron-down</v-icon>
        </span>
      </a>
    </template>
    <v-card class="poppins py-1" elevation="0">
      <template v-for="action in item.extra_data.actions">
        <v-btn
          :key="action.name"
          v-if="action.is_visible"
          class="d-block w-100"
          :disabled="action.disabled"
          text
          @click="
            handle_function_call(`${action.name}ActionFunction`, [
              item,
              action.name,
            ])
          "
          >{{ action.title }}</v-btn
        >
      </template>
      <!-- {{ item.extra_data.actions }} -->
    </v-card>
  </v-menu>
</template>
// handle_function_call(action.method, item);
<script>
/**
 * universal action button creator component for datatables
 * @var item
 */

export default {
  name: "DataTableActionSelectorNew",
  props: {
    item: { required: true },
    actions: { required: true },
    handle_function_call: { required: true },
  },
  computed: {},
};
</script>
