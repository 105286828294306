<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add Skus</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-300px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Body-->

        <form id="createForm" class="pa-3 pb-0">
          <v-autocomplete
            v-model.trim="$v.formData.sku.$model"
            :error-messages="validateState('sku') ? errorMessages : ''"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            :value="value"
            item-value="index"
            hide-details
            class="mb-3"
            dense
            outlined
            label="Please select the System SKU"
            @keyup.enter="getSkuItems"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Search for
                  <strong>Skus</strong>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-text-field
            v-model.trim="$v.formData.system_sku.$model"
            :error-messages="validateState('system_sku') ? errorMessages : ''"
            class="mt-4"
            label="Client SKU name"
            clearable
            dense
            outlined
          ></v-text-field>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import Apiservice from "@/core/services/api.service";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      sku: { required },
      system_sku: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "loader", "integration_id"],
  data: () => ({
    dialog: false,
    isLoading: false,
    search: null,
    items: [],
    value: "",
    formData: {
      sku: null,
      system_sku: null,
    },
    errorMessages: ["This Field is required"],
  }),
  methods: {
    toggleModal() {
      this.resetCreateForm();
      this.dialog = !this.dialog;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? $error : null;
    },
    getSkuItems() {
      if (!this.search) {
        Swal.fire({
          title: "Warning",
          text: "You should input skus search word",
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
      }
      this.isLoading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/validations/skusMapping/search`;
      let data = {
        integration_id: this.integration_id,
        sku: this.search,
      };
      Apiservice.post(url, data)
        .then((res) => {
          const { data } = res;
          if (data.skus) {
            this.items = [];
            data.skus.map((item) => {
              this.items.push({ index: item, text: item });
            });
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.loader(false);
        });
    },
    submitCreateForm() {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        return;
      }
      this.loader(true);
      let data = { ...this.formData, integration_id: this.integration_id };

      Apiservice.post("/validations/skusMapping/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: "The Sku has been Created",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.loader(false);
        });
    },
    message(title, message, icon) {
      Swal.fire({
        title: title,
        text: message,
        icon: icon,
        showConfirmButton: false,
        timer: 2500,
      });
    },
    resetCreateForm() {
      this.formData.sku = null;
      this.formData.system_sku = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>
