<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add New Supplier</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-300px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0">
          <v-text-field
            v-model.trim="$v.formData.supplier.$model"
            :error-messages="validateState('supplier') ? errorMessages : ''"
            label="Supplier"
            clearable
            dense
            outlined
          ></v-text-field>
          <v-text-field
            v-model.trim="$v.formData.system_code.$model"
            :error-messages="validateState('system_code') ? errorMessages : ''"
            label="System_code"
            clearable
            dense
            outlined
          ></v-text-field>
        </form>

        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      supplier: { required, maxLength: maxLength(40) },
      system_code: { required, maxLength: maxLength(40) },
    },
  },
  name: "AddItem",
  props: ["refresher", "loader", "integration_id"],
  data: () => ({
    dialog: false,
    formData: {
      supplier: null,
      system_code: null,
    },
    errorMessages: ["This Field is required"],
  }),
  methods: {
    toggleModal() {
      this.resetCreateForm();
      this.dialog = !this.dialog;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? $error : null;
    },
    submitCreateForm() {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        return;
      }
      this.loader(true);
      let data = { ...this.formData, integration_id: this.integration_id };
      ApiService.post("/validations/supplierMapping/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: "The Supplier Data has been Created",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.loader(false);
        });
    },
    resetCreateForm() {
      this.formData.supplier = null;
      this.formData.system_code = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>
