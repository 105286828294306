<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    persistent
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <button
        class="btn btn-light-facebook btn-sm px-4 custom-btn text-no-wrap"
        @click="toggleModal"
      >
        View
      </button>
    </template>
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center py-3">
        <h3 class="mb-0 font-weight-bolder font-size-h4">Details</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--begin::Modal header-->
      <div
        class="modal-body d-flex flex-column max-h-550px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Row-->
        <div
          class="d-flex align-center mb-2"
          v-for="(itemValue, key, index) in value"
          :key="index"
        >
          <label class="mb-0 font-weight-bolder text-dark-50">
            {{ key }}:
          </label>
          <!--begin::Col-->
          <div class="ml-4">
            <span class="font-weight-medium text-dark-75">{{ itemValue }}</span>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ObjectList",
  props: ["value"],
  data: () => ({
    dialog: false,
  }),
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
