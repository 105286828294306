<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="skus"
      hide-default-footer
      :disable-sort="!forView"
      class="elevation-1"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center px-6 py-3">
          <span class="mx-3 text-dark-50 mt-1 font-size-h5 font-weight-bolder"
            >SKUS</span
          >
          <button
            v-if="forView"
            type="submit"
            class="btn btn-light-info"
            @click="showAddModal"
          >
            New Item
          </button>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="() => showEditModal(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="() => remove(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <AddEditSKUSModal ref="skus" v-if="forView" />
  </div>
</template>

<script>
import AddEditSKUSModal from "@/own/components/orderManagement/subscriptionManagement/AddEditSKUSModal";
import { REMOVE_RULES_SKUS } from "@/core/services/store/subscriptionManagement.module";
export default {
  name: "SKUSDataTable",
  components: { AddEditSKUSModal },
  props: {
    forView: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    skus: function () {
      return this.$store.getters.getSUBSCRIPTIONSRulesSKUS;
    },
    headers: function () {
      return [
        {
          text: "SKU ID",
          value: "sku_id",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "System code",
          value: "systemCode",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Quantity",
          value: "quantity",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        this.forView && {
          text: "Actions",
          value: "actions",
          align: "left",
          sortable: false,
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
      ];
    },
  },
  methods: {
    showEditModal(item) {
      const lastElementIndex = this.skus.findIndex(
        (elem) => elem.sku_id === item.sku_id
      );
      this.$refs.skus.initData(lastElementIndex, item);
    },
    showAddModal() {
      this.$refs.skus.initData(null, {
        sku_id: null,
        quantity: null,
      });
    },
    remove(item) {
      this.$store.commit(REMOVE_RULES_SKUS, item);
    },
  },
};
</script>
