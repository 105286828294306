<template>
  <v-autocomplete
    outlined
    class="mx-1 poppins"
    multiple
    :items="filter['values']"
    v-model="filterData"
    :label="filter.title"
    item-text="text"
    item-value="index"
    dense
    messages=""
    clearable
    small-chips
    deletable-chips
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon :color="filterData.length > 0 ? 'purple darken-2' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Select All </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-autocomplete>
</template>

<script>
import { SET_FILTERS_DATA } from "@/core/services/store/filter.module";
export default {
  name: "FilterMultiSelect",
  props: {
    /**
      * @type {
          {
              name: String,
              title: String,
              related_columns: Array,
              type: String,
              values: Array,
          }
                }
    */
    filter: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    filterData: [],
  }),
  mounted() {
    if (this.filterAllData[`${this.filter.name}`]) {
      this.filterData = this.filterAllData[`${this.filter.name}`];
    }
  },
  computed: {
    filterAllData() {
      return this.$store.getters.getFiltersData;
    },
    selectedAll() {
      return this.filterData.length === this.filter["values"].length;
    },
    selectedSome() {
      return this.filterData.length > 0 && !this.selectedAll;
    },
    icon() {
      if (this.selectedAll) return "mdi-close-box";
      if (this.selectedSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    resetFilterData() {
      this.filterData = [];
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAll) {
          this.filterData = [];
        } else {
          this.filterData = this.filter["values"].map((value) => value.index);
        }
      });
    },
  },
  watch: {
    filterData: {
      handler() {
        const all = this.filterAllData;
        all[`${this.filter.name}`] = this.filterData;
        this.$store.commit(SET_FILTERS_DATA, all);
      },
      deep: true,
    },
  },
};
</script>
