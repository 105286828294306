<template>
  <div v-if="value" class="d-flex align-center align-content-left w-auto py-1">
    <div class="name-main">
      <span class="first-text ml-3 text-left" style="font-size: 12px">
        {{ value.title }}
      </span>
      <span class="first-text ml-3 text-left" style="font-size: 12px">
        {{ value.body }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestUser",
  props: ["value"],
  components: {},
  data: () => ({
    bgColors: [
      "bg-light-success text-success",
      "bg-light-danger text-danger",
      "bg-light-info text-info",
      "bg-light-primary text-primary",
      "bg-light-warning text-warning",
      "bg-light-dark text-dark",
    ],
  }),
  methods: {
    getRandomColors: function () {
      return this.bgColors[Math.floor(Math.random() * this.bgColors.length)];
    },
  },
};
</script>

<style scoped>
.name-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
</style>
