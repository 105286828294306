<template>
  <div v-if="value === '-'" class="second-text">{{ value }}</div>
  <div
    v-else-if="value.name"
    class="d-flex align-center align-content-left py-1 pl-6 pl-sm-0"
  >
    <span
      class="symbol symbol-25 symbol-sm-35 symbol-light-success overflow-hidden mr-3"
    >
      <img
        style="border-radius: 50% !important"
        v-if="value.photo.url"
        alt="photo"
        :src="value.photo.url"
      />
      <span
        v-else
        style="border-radius: 50% !important"
        class="symbol-label font-size-base font-size-h5-sm"
        :class="getRandomColors()"
      >
        {{ value.name[0].toUpperCase() }}
      </span>
    </span>

    <span class="first-text text-capitalize">
      {{ value.name }}
    </span>
  </div>
  <div v-else class="second-text">{{ value }}</div>
</template>

<script>
/**
    Component for displaying client  name with photo
    api type name : clientPhoto
*/
export default {
  name: "ClientColumn",
  props: ["value"],
  data: () => ({
    bgColors: [
      "bg-light-success text-success",
      "bg-light-danger text-danger",
      "bg-light-info text-info",
      "bg-light-primary text-primary",
      "bg-light-warning text-warning",
      "bg-light-dark text-dark",
    ],
  }),
  methods: {
    getRandomColors: function () {
      return this.bgColors[Math.floor(Math.random() * this.bgColors.length)];
    },
  },
};
</script>
