<template>
  <div v-if="table_permissions.length > 0" class="custom-content-height">
    <!-- slot top -->
    <div v-if="$slots.top">
      <slot name="top"></slot>
    </div>
    <!-- slot top end -->
    <v-data-table
      v-model="selected"
      fixed-header
      light
      dense
      :height="getvh(56)"
      :show-select="!disableSelectAllCheckbox"
      :single-select="disableSelectAllCheckbox"
      :items="items"
      :headers="headers"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortOrder"
      :items-per-page="itemPerPage"
      :item-key="id_field_name"
      class="mx-2 mt-0 font-weight-bold"
      hide-default-footer
      mobile-breakpoint="40"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE!</template>
      <template v-slot:no-results> NO RESULTS HERE!</template>
      <!--    no-data end-->
      <!--    table top-->
      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 pt-2 d-flex justify-space-between align-center flex-wrap bg-white position-sticky top-0 zindex-5"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <datatable-detail
            :table_properties="table_properties"
            v-if="$vuetify.breakpoint.smAndUp"
          ></datatable-detail>
          <div
            v-else
            class="d-flex align-center justify-space-between mb-3 w-100"
          >
            <datatable-detail
              :table_properties="table_properties"
            ></datatable-detail>
            <b-button
              v-show="$vuetify.breakpoint.xsOnly"
              depressed
              small
              variant="outline-primary"
              class="px-5 py-3 mb-1 btn--tools"
              @click="toolBarVisionState = !toolBarVisionState"
            >
              TOOLS
            </b-button>
          </div>
          <div
            v-show="isToolbarVisiable"
            style="display: flex; justify-content: center; align-items: center"
            class="card-toolbar ma-0 pa-0 col-md-8 col-sm-10 col-12 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
          >
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="bulkRemoveOption && selected.length > 0"
            >
              <button
                @click.prevent="resetSelectedItems"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-refresh</v-icon>
                </span>
                Reset
              </button>
            </div>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('create')"
            >
              <button
                @click.prevent="showAddItemModal"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-plus-box-outline</v-icon>
                </span>
                <span v-if="addButtonText">Add {{ addButtonText }}</span>
                <span v-else> Add {{ table_properties.title }} </span>
              </button>
            </div>

            <!--begin::Button-->

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('export') && exportHeaders"
            >
              <button
                @click="handleExportSidebarClick"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-export-variant</v-icon>
                </span>
                Export
              </button>
            </div>

            <!--end::Button-->

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="filters.length > 0"
            >
              <button
                @click="handleFilterSidebarClick"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-filter</v-icon>
                </span>
                Filter
              </button>
            </div>

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('print')"
            >
              <button
                class="btn btn--print-upload"
                @click.prevent="showGenerateModal"
              >
                Generate
              </button>
              <component
                v-if="permissionChecker('print')"
                v-bind:is="generateComponent"
                ref="generate"
                :updateTableContent="updateTableContent"
              ></component>
            </div>

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('reprocess')"
            >
              <button
                class="btn btn--print-upload"
                @click.prevent="applyReprocessAction"
              >
                Reprocess
              </button>
            </div>
            <component
              v-bind:is="printComponent"
              v-if="permissionChecker('sku_barcode_generate')"
              :items="selected"
              :types="[
                { name: 'BARCODE', value: 'barcode2d' },
                { name: 'QR', value: 'barcodeQr' },
              ]"
              :class="{ 'col-6': $vuetify.breakpoint.xsOnly }"
            ></component>

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
              v-else-if="permissionChecker('upload')"
            >
              <button
                class="btn btn--print-upload"
                @click.prevent="
                  showUploadModal($store.getters[store_names.getUploadType])
                "
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-cloud-upload</v-icon>
                </span>
                Import
              </button>
              <component
                v-if="permissionChecker('upload')"
                v-bind:is="uploadComponent"
                ref="upload"
                :updateTableContent="updateTableContent"
              ></component>
            </div>

            <component
              v-bind:is="multiUploadComponent"
              v-if="
                (permissionChecker('upload') &&
                  table_properties.title === 'SKUs') ||
                table_properties.title === 'Products'
              "
              :types="$store.getters[store_names.getUploadType]"
              :actionFunction="showUploadModal"
              :class="{ 'col-6': $vuetify.breakpoint.xsOnly }"
            ></component>

            <slot
              name="actionsButton"
              :selectedItems="selected"
              :refresher="updateTableContent"
            ></slot>
          </div>
        </div>
        <!-- Export Sidebar start -->
        <ExportSidebar
          v-if="permissionChecker('export') && exportHeaders"
          ref="exportSidebar"
          :exportHeaders="exportHeaders"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--        end::Export Sidebar-->

        <!-- Filter Sidebar start -->
        <FilterSidebar
          v-if="filters.length > 0"
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--end::Filter Sidebar-->
      </template>
      <template
        v-if="!disableSelectAllCheckbox"
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="allSelected"
            @click="selectAll"
          />
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>
      <!-- column -->
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>
      <template #item.action="{ item, header, value }">
        <component
          v-bind:is="actionsComponent"
          :item="item"
          :actions="actions"
          :handle_function_call="handle_function_call"
        ></component>
      </template>
      <!-- column end -->
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setitemPerPage"
      :getitemPerPage="getitemPerPage"
      :getpageNumber="getpageNumber"
      :setpageNumber="setpageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <component
      v-if="permissionChecker('create')"
      v-bind:is="addComponent"
      :pageLoader="pageLoad"
      :refresher="updateTableContent"
      ref="addItem"
    ></component>
    <component
      v-if="this.permissionChecker('update')"
      v-bind:is="editComponent"
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="editModal"
    ></component>
    <component
      v-if="this.permissionChecker('duplicate')"
      v-bind:is="duplicateComponent"
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="duplicateModal"
    ></component>
    <div v-if="$slots.bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
import ComponentSelector from "@/own/components/datahub/integrations2/IntegrationComponentSelector.vue";
import DataTableActionSelector from "@/own/components/DataTableActionSelector.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import Pagination from "@/own/components/pagination/Pagination.vue";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar.vue";
import ExportSidebar from "@/own/components/sidebars/ExportSidebar.vue";

import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";

export default {
  name: "IntegrationDataTable",
  mixins: [getPageWidthHeight],
  props: {
    id_field_name: {
      type: String,
      default: "id",
    },
    /** @type {{UPDATE_TABLE_STRUCTURE:string,UPDATE_TABLE_DATA:string,SET_TABLE_SORTBY:string,SET_TABLE_FILTER:string,SET_TABLE_PERPAGE:string,SET_TABLE_PAGE:string,EXPORT_TABLE_DATA:string}} */
    table_state: {
      type: Object,
      required: true,
    },
    /** @type {{getTableFilters:string,getTableState:string,getTableData:string,getTableHeaders:string,getTableProperties:string,getTableExportUrl:string,getTablePermissions:string}} */
    store_names: {
      type: Object,
      required: true,
    },
    addComponent: {},
    editComponent: {},
    duplicateComponent: {},
    uploadComponent: {},
    multiUploadComponent: {},
    generateComponent: {},
    printComponent: {},
    addButtonText: {},
    delete_url: {
      type: String,
    },
    /** @type {{activate: string, delete: string, status: string}} */
    urls: {
      type: Object,
    },
    disableSelectAllCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
    bulkRemoveOption: {
      type: Boolean,
      default: false,
      required: false,
    },
    isFilterRequiredForExport: {
      type: Boolean,
      default: false,
    },
    actionsComponent: {
      default: () => DataTableActionSelector,
    },
  },
  components: {
    DataTableActionSelector,
    DatatableDetail,
    FilterSidebar,
    ExportSidebar,
    Pagination,
    ComponentSelector,
  },
  data: () => ({
    toolBarVisionState: false,
    selected: [],
    allSelected: false,
  }),
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.smAndUp ? true : this.toolBarVisionState;
    },
    table_permissions: function () {
      /** @type {Array<string>} */
      let permissions = [];
      try {
        if (this.$store.getters[this.store_names.getTablePermissions]) {
          permissions = this.$store.getters[
            this.store_names.getTablePermissions
          ].map(function (perm) {
            return perm.name;
          });
        }
      } catch (error) {
        // this.$swal("Can not load table permissions!");
      }
      return permissions;
    },
    table_properties: function () {
      /** @type {{title:string, description: string}} */
      let properties = null;
      try {
        properties = this.$store.getters[this.store_names.getTableProperties];
      } catch (error) {
        // this.$swal("Can not load table properties!");
      }
      return properties;
    },
    orderBy: {
      get: function () {
        /** @type {string} */
        let sortBy = this.$store.getters[this.store_names.getTableState].sortBy;
        return sortBy;
      },
      set: function (newValue) {
        if (newValue) {
          this.$store.commit(this.table_state.SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(
            this.table_state.UPDATE_TABLE_DATA,
            this.$store.getters[this.store_names.getTableState]
          );
        } else {
          this.$store.commit(this.table_state.SET_TABLE_SORTBY, "");
          this.$store.dispatch(
            this.table_state.UPDATE_TABLE_DATA,
            this.$store.getters[this.store_names.getTableState]
          );
        }
      },
    },
    sortOrder: {
      get: function () {
        let sortOrder = true;
        if (
          this.$store.getters[this.store_names.getTableState].sortOrder ===
          "asc"
        ) {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function (newValue) {
        if (newValue === true) {
          this.$store.commit(this.table_state.SET_TABLE_SORTORDER, "desc");
        } else {
          this.$store.commit(this.table_state.SET_TABLE_SORTORDER, "asc");
        }
        this.$store.dispatch(
          this.table_state.UPDATE_TABLE_DATA,
          this.$store.getters[this.store_names.getTableState]
        );
      },
    },
    headers: function () {
      /**
         * @type {Array<
            {
                value: string,
                text: string,
                type: string,
                sortable: string,
                exportable: string,
                visible: string,
                mobile_visible: string,
                align: string,
                itemClass: string,
                width: string,
                class: string,
                export_order: integer,
              }>
                  }
          */
      let headers = [];
      try {
        if (this.$vuetify.breakpoint.smAndDown) {
          headers = this.$store.getters[
            this.store_names.getTableHeaders
          ].filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        } else {
          headers = this.$store.getters[
            this.store_names.getTableHeaders
          ].filter((col) => col.visible === true);
        }
        headers.forEach((header) => {
          header.class =
            "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
          header.itemClass = "second-text";
          header.width = undefined;
          if (header.value === "action") {
            header.class =
              "pr-10 text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
          }
        });
      } catch {
        // headers = this.$store.getters[this.store_names.getTableHeaders].filter(
        //   (col) => col.visible === true
        // );
      }

      return headers;
    },
    exportHeaders: function () {
      /** @type {Array<{order:string,text:string,value:string}>} */
      let exportHeaders = [];
      try {
        exportHeaders = this.$store.getters[
          this.store_names.getTablePermissions
        ].filter((col) => col.name === "export")[0].columns;
      } catch (error) {
        exportHeaders = [];
      }
      return exportHeaders;
    },
    items: function () {
      /**
       * @type {Array<{}>}
       * @example [{"id":891,"email":"rafi.ogchy@gmail.com","extra_data":null}]
       */
      let items = [];
      try {
        items = this.$store.getters[this.store_names.getTableData].data;
      } catch {
        items = null;
      }
      return items;
    },
    //-------------------activities------------------------
    actions: function () {
      return [
        {
          title: "View",
          type: "normal",
          method: "activateView",
          isVisible: this.permissionChecker("show"),
        },
        {
          title: "Reset",
          type: "normal",
          method: "resetItem",
          isVisible: this.permissionChecker("reset"),
        },
        {
          title: "Reset Password",
          type: "normal",
          method: "activateAction",
          isVisible: this.permissionChecker("activate"),
        },
        {
          title: "Refresh token",
          type: "normal",
          method: "generateAction",
          isVisible: this.permissionChecker("generate"),
        },
        {
          title: "Status",
          type: "status",
          method: "statusAction",
          isVisible: this.permissionChecker("status"),
        },
        {
          title: "Edit",
          type: "normal",
          method: "editItem",
          isVisible: this.permissionChecker("update"),
        },
        {
          title: "Remove",
          type: "normal",
          method: "removeItem",
          isVisible: this.permissionChecker("destroy"),
        },
        {
          title: "Export",
          type: "normal",
          method: "exportChecklist",
          isVisible: this.permissionChecker("export_checklists"),
        },
        {
          title: "Duplicate",
          type: "normal",
          method: "duplicateAction",
          isVisible: this.permissionChecker("duplicate"),
        },
        {
          title: "Export SKU",
          type: "normal",
          method: "exportSkuAction",
          isVisible:
            this.permissionChecker("export") &&
            this.table_properties.title === "Bundles",
        },
      ];
    },
    //-------------------pagination----------------------
    getitemPerPage: function () {
      /** @type {number} */
      let itemPerPage =
        this.$store.getters[this.store_names.getTableState].per_page;
      return itemPerPage;
    },
    getpageNumber: function () {
      /** @type {number} */
      let pageNumber = this.$store.getters[this.store_names.getTableState].page;
      return pageNumber;
    },
    totalItems: function () {
      /** @type {number} */
      let totalItems = this.$store.getters[this.store_names.getTableData].total;
      return totalItems;
    },
    pageCount: function () {
      /** @type {number} */
      let pageCount =
        this.$store.getters[this.store_names.getTableData].last_page;
      return pageCount;
    },
    itemPerPage: function () {
      /** @type {number} */
      let itemPerPage =
        this.$store.getters[this.store_names.getTableState].per_page;
      return itemPerPage;
    },
    //-------------------filter----------------------
    filters: function () {
      /** @type {Array<{name: string, title: string, type: string, related_columns: string[]}>} */
      let filters = [];
      try {
        filters = this.$store.getters[this.store_names.getTableFilters];
      } catch {
        filters = [];
      }
      return filters;
    },
  },
  methods: {
    // connector for action functions
    editActionFunction(props) {
      this.editItem(props[0]);
    },
    pendingActionFunction(props) {
      this.statusActionNew(props[0], props[1]);
    },
    verifiedActionFunction(props) {
      this.statusActionNew(props[0], props[1]);
    },
    disabledActionFunction(props) {
      this.statusActionNew(props[0], props[1]);
    },
    openActionFunction(props) {
      this.statusActionNew(props[0], props[1]);
    },
    resolvedActionFunction(props) {
      this.statusActionNew(props[0], props[1]);
    },
    unresolvedActionFunction(props) {
      this.statusActionNew(props[0], props[1]);
    },
    removeActionFunction(props) {
      this.removeItem(props[0]);
    },
    add_commentActionFunction(props) {
      this.addComment(props[0]);
    },
    upload_docActionFunction(props) {
      this.$store.commit(SET_ITEM_FOR_ACTION, props[0]);
      this.$refs.uploadDoc.dialog = true;
    },
    statusActionNew(item, type) {
      let data = { id: item.id, status: type };
      ApiService.post(this.urls.status, data)
        .then(() => {
          Swal.fire("Activation!", "Status updated!", "success");
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },

    // end of connectors
    /**
     * used to call functions by name
     * @param {string} function_name - name of function
     * @param {string} prop - property for function
     */
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    handleExportSidebarClick() {
      this.$refs.exportSidebar.$data.exportSidebar = true;
    },
    handleFilterSidebarClick() {
      this.$refs.filterSidebar.$data.filterSidebar = true;
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length < this.getitemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getitemPerPage * this.getpageNumber - this.getitemPerPage;
          innerItems = this.items.slice(startFrom, this.getitemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
    //\\
    onResize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toolBarVisionState = false;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        this.toolBarVisionState = true;
      }
    },
    /**
     * Check is there permission included
     * @param {string} permType - permission type
     * @returns {boolean}
     */
    permissionChecker(permType) {
      return this.table_permissions.includes(permType);
    },

    /**
     * set app loader status
     * @param {boolean} state - state to set
     */
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    async updateTableContent() {
      this.pageLoad(true);
      await this.$store
        .dispatch(this.table_state.UPDATE_TABLE_DATA, {
          ...this.$store.getters[this.store_names.getTableState],
          integration_id: this.$store.getters.getINTEGRATIONS2IntegrationId,
        })
        .then(() => {
          this.selected = [];
        });
      this.$forceUpdate();
      this.pageLoad(false);
    },

    showAddItemModal() {
      this.$store.commit(SET_ITEM_FOR_ACTION, null);
      this.$refs.addItem.toggleModal();
    },
    showUploadModal(type) {
      this.$router.push({
        name: "settings.uploads",
        query: { type: type },
      });
    },
    showGenerateModal() {
      this.$refs.generate.toggleModal();
    },
    applyReprocessAction() {
      if (this.selected.length > 0) {
        this.pageLoad(true);
        const data = { id: this.selected.map((row) => row.id) };
        ApiService.post(this.urls.reprocess, data)
          .then(() => {
            this.pageLoad(false);
            Swal.fire("Reprocess!", "Data has been reprocessed.", "success");
            this.selected = [];
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one record!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    //----------------------activities-------------------------
    activateView(item) {
      let routeData = this.$router.resolve({
        name: "invoiceTemp",
        query: { id: item.id },
      });
      window.open(routeData.href, "_blank");
    },
    resetItem(item) {
      const data = { id: item.id };
      ApiService.post(this.urls.reset, data)
        .then(() => {
          Swal.fire("Reset!", "Record has been reset.", "success");
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    editItem(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.editModal.toggleModal();
    },
    exportChecklist(item) {
      this.$refs.editModal.exportChecklist(item);
    },
    removeItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          //TODO: change data sending to id
          const data = {
            id: item.id,
            integration_id: this.$store.getters.getINTEGRATIONS2IntegrationId,
          };
          ApiService.post(this.delete_url, data)
            .then(() => {
              Swal.fire("Deleted!", "Record has been deleted.", "success");
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    generateAction(item) {
      this.pageLoad(true);
      let data = { id: item.id };
      ApiService.post(this.urls.generate, data)
        .then((res) => {
          this.$emit("handleTokenModal", res.data.token);
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    activateAction(item) {
      this.pageLoad(true);
      let data = { id: item.id };
      ApiService.post(this.urls.activate, data)
        .then(() => {
          Swal.fire(
            "Activation!",
            "Activation succeed, check email!",
            "success"
          );
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    exportSkuAction(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      // console.log("order table state", this.$store.getters.getORDERSTableState);
      ApiService.post("/fulfillment/bundles/export", {
        id: item.id,
        export_type: "skus",
        columns: [
          ...this.$store.getters[this.store_names.getExportSkuColumns].map(
            (column) => column.value
          ),
        ],
      }).then((res) => {
        const link = document.createElement("a");
        link.href = res.data.url;
        link.download = "Export.csv";
        link.click();
        setTimeout(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        }, 1500);
        link.remove();
      });
    },
    duplicateAction(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.duplicateModal.toggleModal();
    },
    statusAction(item) {
      if (item.status_label === "Cancelled") return;
      this.pageLoad(true);
      let status = null;
      if (item.status_label === "Disabled" || item.status_label === "Draft") {
        status = "Enabled";
      } else status = "Disabled";
      let data = { id: item.id, status };
      ApiService.post(this.urls.status, data)
        .then(() => {
          Swal.fire("Activation!", "Status updated!", "success");
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },

    //------------------pagination-------------------
    setitemPerPage(val) {
      this.$store.commit(this.table_state.SET_TABLE_PERPAGE, val);
    },
    setpageNumber(val) {
      this.$store.commit(this.table_state.SET_TABLE_PAGE, val);
    },
    //---------------------export-------------------------
    isAnyFilterSelected() {
      return this.$store.getters[this.store_names.getTableState].filters
        .length > 0
        ? true
        : false;
    },
    downloadItem(columns) {
      if (this.isFilterRequiredForExport && !this.isAnyFilterSelected()) {
        this.$refs.exportSidebar.$data.exportSidebar = false;
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one filter!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      const payload = {
        columns: columns,
        filters: this.$store.getters[this.store_names.getTableState].filters,
        integration_id: this.$store.getters.getINTEGRATIONS2IntegrationId,
      };

      if (this.selected.length) {
        payload.export_ids = this.selected.map((item) => item.id);
      }

      this.$store
        .dispatch(this.table_state.EXPORT_TABLE_DATA, payload)
        .then(() => {
          const link = document.createElement("a");
          link.href = this.$store.getters[this.store_names.getTableExportUrl];
          link.download = "ProblemWithExport.csv";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
          // this.$refs.exportSidebar.$refs.export.toggleModal();
          this.$refs.exportSidebar.$data.exportSidebar = false;
          link.remove();
        });
    },

    //--------------------filter---------------------
    setTableFiltersValues(val) {
      this.$store.commit(this.table_state.SET_TABLE_FILTER, val);
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = {
        ...this.$store.getters[this.store_names.getTableState],
        integration_id: this.$store.getters.getINTEGRATIONS2IntegrationId,
      };
      state.page = 1;
      this.$store
        .dispatch(this.table_state.UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(this.table_state.SET_TABLE_PAGE, 1);

          this.$nextTick(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
            this.selected = [];
          });
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store.commit(this.table_state.SET_TABLE_FILTER, []);
      this.$store
        .dispatch(this.table_state.UPDATE_TABLE_DATA, {
          ...this.$store.getters[this.store_names.getTableState],
          integration_id: this.$store.getters.getINTEGRATIONS2IntegrationId,
        })
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
          this.selected = [];
        });
    },
    resetSelectedItems() {
      if (this.selected.length === 0) {
        return;
      }
      let idArr = [];
      this.selected.map((item) => {
        idArr.push(item.id);
      });
      Swal.fire({
        title: "Are you sure?",
        text: "You will reset all the selected items!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reset them!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          //TODO: change data sending to id
          const data = { id: idArr };
          ApiService.post(this.urls.reset, data)
            .then(() => {
              Swal.fire("Reset!", "Records has been reset.", "success");
              this.selected = [];
              this.updateTableContent();
            })
            .catch(() => {
              this.selected = [];
              this.pageLoad(false);
            });
        }
      });
    },
  },
  beforeDestroy() {
    this.$store.commit(this.table_state.SET_TABLE_FILTER, []);
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
};
</script>

<style scoped>
.q-status-menu {
  max-height: 350px;
  overflow-y: scroll;
}
.drop-item {
  font-weight: 500;
  color: rgb(98, 98, 98);
}
.drop-item:hover {
  font-weight: 600;
  background-color: #eee5ff;
  color: #8950fcff !important;
}
.drop-text {
  font-size: 9px;
}
</style>
