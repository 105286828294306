<template>
  <div v-if="value" class="d-flex align-center align-content-left w-auto py-1">
    <!-- <span class="symbol symbol-35 symbol-light-success overflow-hidden mr-3">
      <img
        style="border-radius: 50% !important"
        v-if="value.image"
        alt="photo"
        :src="value.image"
      />
      <span
        v-else
        style="border-radius: 50% !important"
        class="symbol-label font-size-h5"
        :class="getRandomColors()"
      >
        {{ value.text1[0].toUpperCase() }}
      </span>
    </span> -->
    <ShowImage
      :item="{
        url: value?.image,
        first_letter: Boolean(value?.text1) ? value?.text1[0] : 'S',
      }"
    ></ShowImage>

    <div class="name-main">
      <span class="first-text ml-3 text-left" style="font-size: 12px">
        {{ value.text1 }}
      </span>
      <span class="first-text ml-3 text-left" style="font-size: 12px">
        {{ value.text2 }}
      </span>
    </div>
  </div>
</template>

<script>
import ShowImage from "@/own/components/datatable/ShowImage";
export default {
  name: "ImageTitleDescription",
  props: ["value"],
  components: {
    ShowImage,
  },
  data: () => ({
    bgColors: [
      "bg-light-success text-success",
      "bg-light-danger text-danger",
      "bg-light-info text-info",
      "bg-light-primary text-primary",
      "bg-light-warning text-warning",
      "bg-light-dark text-dark",
    ],
  }),
  methods: {
    getRandomColors: function () {
      return this.bgColors[Math.floor(Math.random() * this.bgColors.length)];
    },
  },
};
</script>

<style scoped>
.name-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
</style>
