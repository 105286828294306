<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Order Prefix</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-300px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0">
          <v-switch
            v-model="formData.prefix_replace"
            color="primary"
            label="Order Prefix"
          />
          <v-text-field
            :disabled="!formData.prefix_replace"
            v-model.trim="$v.formData.prefix_replace_char.$model"
            :error-messages="
              validateState('prefix_replace_char')
                ? ['This field is required']
                : ''
            "
            type="number"
            label="Prefix Replace Char"
            required
            dense
            outlined
            clearable
            @change="() => validateMinValue('prefix_replace_char', 0)"
            :min="0"
          ></v-text-field>
        </form>

        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import Apiservice from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      prefix_replace_char: { required, maxLength: maxLength(40) },
    },
  },
  name: "OrderPrefix",
  props: ["loader", "integration_id"],
  data: () => ({
    dialog: false,
    formData: {
      prefix_replace: null,
      prefix_replace_char: null,
    },
    flagData: null,
  }),
  methods: {
    toggleModal() {
      this.getOrderPrefix();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? $error : null;
    },
    getOrderPrefix() {
      this.loader(true);
      const data = {
        integration_id: this.integration_id,
      };
      Apiservice.post("/validations/orderPrefix", data)
        .then((response) => {
          this.flagData = response.data;
          this.resetData();
          this.loader(false);
          this.dialog = true;
        })
        .catch(() => {
          this.loader(false);
        });
    },
    submitCreateForm() {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        return;
      }
      this.loader(true);
      const data = { ...this.formData, integration_id: this.integration_id };
      Apiservice.post("/validations/orderPrefix/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: "The Order Prefix Data has been updated",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.dialog = false;
          this.loader(false);
        })
        .catch(() => {
          this.loader(false);
        });
    },
    resetData() {
      this.formData = {
        prefix_replace: this.flagData.prefix_replace,
        prefix_replace_char: this.flagData.prefix_replace_char,
      };
    },
  },
};
</script>
